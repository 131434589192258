.ggbutton {
    height: 2em;
    width: 7em;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.title {
    font-size: x-large;
    color: #3F4B5C;
    margin-bottom: 5px;
}

.loginButton {
    margin-top: 10px;
}
