.user-detail-container {
    max-width: 1200px;
    display: block;
    padding: auto;
    margin: auto;
    overflow: auto;
}
@media only screen and (min-width: 600px) {
    .user-detail-container {
        height: calc(100vh - 106px);
    }
}
@media only screen and (max-width: 600px) {
    .user-detail-container {
        height: calc(100vh - 124px);
    }
}
.user-detail-content {
    width: 90%;
    box-sizing: border-box;
    /* margin-left: 1.6875rem; */
    margin: auto;
    margin-top: 1rem;
    min-width: 0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 13%);
    border-radius: .125rem;
    padding: 20px;
}
.user-detail-header {
    border-bottom: 1px solid #efefef;
}
.user-detail-title {
    align-self: center;
    text-align: center;
    -webkit-text-size-adjust: 100%;
    font-family: Helvetica Neue,Helvetica,Arial,文泉驛正黑,WenQuanYi Zen Hei,Hiragino Sans GB,儷黑 Pro,LiHei Pro,Heiti TC,微軟正黑體,Microsoft JhengHei UI,Microsoft JhengHei,sans-serif;
    -webkit-box-direction: normal;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #333;
    text-transform: capitalize;
    margin-bottom: 1rem;
}
.user-detail-info {
    margin-top: 1rem;
}
.user-detail-name-container {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
}
.user-detail-name-label {
    width: 20%;
    text-align: right;
    color: rgba(85,85,85,.8);
    text-transform: capitalize;
    overflow: hidden;
}
.user-detail-name-text {
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden; 
}
.user-detail-image-container {
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
@media only screen and (max-width: 600px) {
    .user-detail-name-text {
        text-align: right;
    }
  }
@media only screen and (min-width: 600px) {
    .user-detail-name-text {
        text-align: left;
        margin-left: 2rem;
    }
}
.user-detail-image {
    width: 150px;
    height: 150px;
    border-radius: 75px;
    margin: auto;
}
.user-detail-phone-container {
    display: flex;
    width: 80%;
}
.user-detail-phone-content {
    background-color: #fff;
    position: relative;
    border-radius: 2px;
    border: 1px solid rgba(0,0,0,.14);
    box-sizing: border-box;
    box-shadow: inset 0 2px 0 0 rgb(0 0 0 / 2%);
    color: #222;
    height: 40px;
    padding: 10px;
    transition: border-color .1s ease;
    width: 80%;
}
@media only screen and (min-width: 600px) {
    .user-detail-phone-container {
        margin-left: 2rem;
    }
  }
@media only screen and (max-width: 600px) {
    .user-detail-phone-container {
        justify-content: flex-end;
    }
}
.user-detail-phone-input {
    width: 100%;
}
.update-button {
    display: block;
    visibility: visible;
    font: inherit;
    margin: auto;
    overflow: visible;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    padding: auto;
    border: 0;
    cursor: pointer;
    height: 2.625rem;
    background-color: #ee4d2d;
    color: #fff;
    text-transform: capitalize;
    border-radius: 2px;
    margin-top: .875rem;
}
.update-button-text {
    margin-left: 2rem;
    margin-right: 2rem;
}
.user-detail-error-label {
    color: #ff424f;
    margin-top: .3125rem;
    text-align: center;
}
.user-bottom-container {
    max-width: 1200px;
    display: block;
    padding: auto;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.logout-button {
    display: flex;
    margin: auto;
    padding: auto;
    max-width: 6rem;
    height: 2.65rem;
    background-color: white;
    color: black;
    border-width: 1px;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
}
.logout-button-text {
    text-align: center;
}
.user-detail-footer {
    height: 6rem;
    width: 100vw;
}