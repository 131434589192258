.navbar-wrapper {
height: 2.125rem;
box-sizing: border-box;
z-index: 400;
background: transparent;
min-width: inherit;
}
.container-wrapper {
    width: inherit;
min-width: 1200px;
}
.container, .container-fluid {
margin-right: auto;
margin-left: auto;
}
.container-navbar {
}
.container-navbar-right {
    display: inline-block;
    position: absolute;
    right: 5px;
}
.navbar-wrapper .navbar {
color: rgb(255, 255, 255);
}
.v-center {
display: block;
-webkit-box-align: center;
align-items: center;
}
.nav-top-link {
    display: flex;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 0.8125rem;
    font-weight: 300;
    justify-content: center;
}
.nav-top-title {
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0px 0.4375rem;
    }
.white-bear-drawer {
position: relative;
}
.nav-top-connect {
padding-right: 0px;
border: 0px;
}
.nav-top-left-icon-container {
    display: flex;
border: 0px;
}
.nav-top-left-icon {
width: 16px;
height: 16px;
display: inline-block;
overflow: hidden;
text-indent: -9999px;
text-align: left;
margin-right: 10px;
background-size: 562.5% 356.25%;
background-image: url("https://i.ibb.co/3pdhs74/e7fba41cad3372e2d3294d28ccbf4611.png");
}
.nav-top-left-container-messenger {
    display: flex;
    }
.nav-top-left-messenger {
    width: 18px;
    height: 18px;
    display: inline-block;
    overflow: hidden;
    text-indent: -9999px;
    text-align: left;
    margin-right: 10px;
    color: white;
    }
.facebook-image {
background-position: 64.8649% 12.1951%;
}
.instagram-image {
background-position: 64.86486486486487% 12.195121951219512%;
background-position-x: 58.1081%;
background-position-y: 75.6098%;
}
.navbar__spacer {
    -webkit-box-flex: 1;
    flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
}
.navbar__links {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}
.navbar_links_login {
    cursor: pointer;
    color: currentColor;
    font-weight: 500;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 2.125rem;
    font-size: .8125rem;
    text-transform: capitalize;
    user-select: none;
    position: relative;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0 .625rem;
    color: #fff;
}
.nav-top-download {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    color: #fff;
    font-size: .8125rem;
    font-weight: 500;
    position: relative;
    display: flex;
}