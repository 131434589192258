@media only screen and (min-width: 600px) {
    .detail-page-container {
        height: calc(100vh - 106px);
    }
}
@media only screen and (max-width: 600px) {
    .detail-page-container {
        height: calc(100vh - 124px);
    }
}

.detail-page-container {
    margin-right: auto;
    margin-left: auto;
    overflow: auto;
}
.detail-page-bottom {
    width: 100vw;
    margin-top: 5rem;
  }
@media only screen and (min-width: 600px) {
    .product-briefing {
        margin-top: .5rem;
        display: flex;
        margin-left: auto;
        border-radius: 3px;
        box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%);
        background: #fff;
        width: 100%;
        justify-content: center;
    }
}
@media only screen and (max-width: 600px) {
    .product-briefing {
        margin-top: .5rem;
        display: block;
        border-radius: 3px;
        box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%);
        background: #fff;
    }
}

@media only screen and (min-width: 600px) {
    .product-image {
        display: inline-block;
    }
}
@media only screen and (max-width: 600px) {
    .product-image {
        display: inline-block;
        width: 100%;
    }
}
@media only screen and (min-width: 600px) {
    .detail-image {
        width: 450px;
        height: 450px;
    }
}
@media only screen and (max-width: 600px) {
    .detail-image {
        width: 100%;
        height: 100vw;
    }
}
@media only screen and (min-width: 600px) {
    .product-detail-container {
        display: inline-block;
        vertical-align: top;
        justify-content: space-between;
        align-items: stretch;
        padding: 1rem;
        margin-left: 1rem;
        border: 0.1px solid rgba(0,0,0,.16);
        border-radius: 0.2rem;
        width: 100%;
    }
}
@media only screen and (max-width: 600px) {
    .product-detail-container {
        display: inline-block;
        vertical-align: top;
        justify-content: space-between;
        align-items: stretch;
        margin-top: 1rem;
        width: 100%;
    }
}
@media only screen and (min-width: 600px) {
    .detail-image-bellow {
        width: 450px;
    }
}
@media only screen and (max-width: 600px) {
    .detail-image-bellow {
        width: 100%;
    }
}
.product-detail-content {
    display: block;
}
.product-title {
    height: auto;
    display: inline-block;
    text-align: center;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-weight: 500;
    vertical-align: sub;
    max-height: 3rem;
    line-height: 1.5rem;
    overflow: hidden;
    max-width: 41.5625rem;
    font-size: 1.25rem;
    word-wrap: break-word;
    margin: auto;
    margin-top: .75rem;
    margin-bottom: 1.25rem;
    color: black;
}
.product-price-container {
    margin-top: 10px;
    padding: 15px 20px;
    background: #fafafa;
    align-items: center;
    min-height: 1.875rem;
}
.product-price {
    font-size: 1.875rem;
    font-weight: 500;
    color: #ee4d2d;
    text-align: center;
}
.product-guarantee {
    font-size: 1.875rem;
    font-weight: 300;
    color: #ee4d2d;
    text-align: center;
}
.product-number-container {
    margin-top: 1.5625rem;
    color: #757575;
    display: flex;
}
.product-number {
    color: #757575;
    text-transform: capitalize;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-left: 1rem;
}
.product-count-number {
    background: #fff;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    margin-left: 1.25rem;
}
.plus-button1 {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    outline: none;
    cursor: pointer;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4,0,.6,1);
    border: 1px solid rgba(0,0,0,.09);
    border-radius: 2px;
    background: transparent;
    color: rgba(0,0,0,.8);
    width: 32px;
    height: 32px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.plus-svg-icon {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    font: inherit;
    text-transform: none;
    cursor: pointer;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    color: rgba(0,0,0,.8);
    x: 0;
    y: 0;
    display: inline-block;
    fill: currentColor;
    position: relative;
    overflow: hidden;
    font-size: 10px;
    width: 10px;
    height: 10px;
}
.plus-button2 {
    width: 50px;
    height: 32px;
    border-left: none;
    border-right: none;
    font-size: 16px;
    font-weight: 400;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    cursor: text;
    border-radius: 0;
    -webkit-appearance: none;
}
@media only screen and (max-width: 600px) {
    .add-to-cart-container {
        margin-bottom: 15px;
    }
  }
.add-to-cart-container {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}
.add-to-cart {
    -webkit-text-size-adjust: 100%;
    font: inherit;
    margin: 0;
    -webkit-appearance: button;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-transform: capitalize;
    outline: 0;
    cursor: pointer;
    height: 48px;
    background: rgba(255,87,34,.1);
    border: 1px solid #ee4d2d;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.03);
    color: #ee4d2d;
    min-width: 11.5rem;
    font-size: 14px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    margin-left: 2px;
}
.add-to-cart-svg {
    -webkit-text-size-adjust: 100%;
    font: inherit;
    text-transform: capitalize;
    cursor: pointer;
    -webkit-box-direction: normal;
    x: 0;
    y: 0;
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    position: relative;
    color: #ee4d2d;
    stroke: #ee4d2d;
    font-size: 1.25rem;
    overflow: hidden;
}
.by-now {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    font: inherit;
    margin: 0;
    -webkit-appearance: button;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex-direction: column;
    box-sizing: border-box;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.09);
    border-radius: 2px;
    border: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-transform: capitalize;
    outline: 0;
    cursor: pointer;
    height: 48px;
    color: #fff;
    background: #ee4d2d;
    min-width: 11rem;
    font-size: 14px;
    margin-right: 2px;
}
.line-title {
    -webkit-text-size-adjust: 100%;
    font-family: Helvetica Neue,Helvetica,Arial,文泉驛正黑,WenQuanYi Zen Hei,Hiragino Sans GB,儷黑 Pro,LiHei Pro,Heiti TC,微軟正黑體,Microsoft JhengHei UI,Microsoft JhengHei,sans-serif;
    line-height: 1.2;
    background: rgba(0,0,0,.02);
    color: rgba(0,0,0,.87);
    font-size: 1.125rem;
    margin-top: 2rem;
    padding: 0.875rem;
    text-transform: capitalize;
    width: 100%;
}
.text-data-container {
    margin-top: 1.25rem;
    white-space: pre-wrap;
    line-height: 2em;
    position: relative;
}
.text-data {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    padding-bottom: 2rem;
}
.user-detail-error-label {
    color: #ff424f;
    margin-top: .3125rem;
    text-align: center;
}
.detail-page-footer {
    height: 6rem;
    padding-bottom: 6rem;
    width: 100vw;
}
.image-bellow-detail-container {
    width: 100%;
    display: flex;
    position: relative;
}
.image-bellow-detail-content {
    width: 100%;
    display: flex;
    position: relative;
    overflow: scroll;
    border-top-width: 0.1rem;
    border-bottom-width: 0.1rem;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.image-bellow-detail-content::-webkit-scrollbar {
    display: none;
  }
.small-image-item {
    width: 5.1rem;
    height: 5.1rem;
    margin: .7rem;
    margin: 0.3rem;
    border-width: .2rem;
}
.small-image-detail-see-more {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:  rgba(0,0,0,.02);
    border-width: .01rem;
}
.small-image-detail-see-more-icon {
    width: 1rem;
    height: 1rem;
    color: #828282;
}
.detail-screen-place-holder {
    height: 100vh;
    width: 100vw;
}
