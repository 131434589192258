.delete-data-container {
    max-width: 1200px;
    padding: auto;
    margin: auto;
    justify-content: center;
    background: #f5f5f5;
    overflow: auto;
}
@media only screen and (min-width: 600px) {
    .delete-data-container {
        height: calc(100vh - 3rem);
    }
}
@media only screen and (max-width: 600px) {
    .delete-data-container {
        height: calc(100vh - 3rem);
    }
}
.search-bar {
    height: min-content;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.search-box {
    border-radius: 2px;
    height: 2rem;
    width: 70vw;
    padding: 5px;
    margin: auto;
}
.result-bar {
    height: max-content;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.result-item {
    border-radius: 2px;
    border-width: 0.5px;
    border-color: gray;
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    vertical-align: center;
    display: flex;
    position: relative;
}
.result-text {
    align-self: center;
    padding-left: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-right: 1rem;
}
.result-image {
    width: 50px;
    height: 50px;
}
.result-delete-button {
    position: absolute;
    right: 0;
    height: 100%;
    border: 0;
    cursor: pointer;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: .2rem;
}
.result-delete-button-icon {
    width: 1.5rem;
    height: 1.5rem;
}
.link-data {
    margin-top: .5rem;
    margin-left: .5rem;
    color: #5776A4;
}