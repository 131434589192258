.search-page {
    margin-right: auto;
    margin-left: auto;
    padding-top: .05rem;
    align-items: center;
    display: block;
}
.search-page-bottom {
    width: 100vw;
    margin-top: 5rem;
  }
@media only screen and (min-width: 600px) {
    .search-page {
        height: calc(100vh - 106px - 2rem);
        overflow: auto;
    }
}
@media only screen and (max-width: 600px) {
    .search-page {
        height: calc(100vh - 124px);
        overflow: auto;
    }
}
.search-content {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 1rem;
}
.li-test-search {
    float: left;
    text-decoration: none;
    box-shadow: 0 0.0625rem 0.125rem 0 rgb(0 0 0 / 10%);
    margin-bottom: 10px;
    background: white;
    margin-left: 1rem;
}
@media only screen and (min-width: 600px) {
    .search-cart-item__cell-checkbox {
        padding: 0 12px 0 20px;
        min-width: 58px;
    }
  }
@media only screen and (max-width: 600px) {
    .search-cart-item__cell-checkbox {
        margin-left: 10px;
        margin-right: 10px;
    }
}
.search-cart-item__cell-checkbox {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    text-transform: capitalize;
    color: #888;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
    box-sizing: border-box;
}
.search-cart-page-product-header__product {
    -webkit-text-size-adjust: 100%;
    font-size: .875rem;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    visibility: visible;
    font-weight: 400;
    color: #555;
    margin: 0 .3125rem 0 0;
}
.search-cart-page-product-header {
    -webkit-text-size-adjust: 100%;
    font-size: .875rem;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    color: rgba(0, 0, 0, 0.8);
    line-height: 1.2;
    visibility: visible;
    font-weight: 400;
    background: rgba(0,0,0,.03);
    padding: .8125rem 1.25rem;
    border-radius: 2px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-left: 0.5rem;
    margin-right: .5rem;
}
.search-result-header {
    line-height: 1.2;
    visibility: visible;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    margin-left: .5rem;
    margin-right: .5rem;
    color: #555;
}
.svg-icon-icon-hint-bulb {
    line-height: 1.2;
    visibility: visible;
    -webkit-box-direction: normal;
    font-size: 1rem;
    font-weight: 400;
    display: inline-block;
    fill: currentColor;
    overflow: hidden;
    position: relative;
    top: .125rem;
    width: 1rem;
    height: 1.375rem;
    margin: 0 .75rem 0 0;
    color: #555;
}
.search-result-header__text {
    line-height: 1.2;
    visibility: visible;
    -webkit-box-direction: normal;
    font-size: 1rem;
    font-weight: 400;
    color: #555;
    padding: .1875rem 0;
}
.search-result-header__text-highlight {
    line-height: 1.2;
    visibility: visible;
    -webkit-box-direction: normal;
    font-size: 1rem;
    padding: .1975rem 0;
    color: #ee4d2d;
    font-weight: 700;
    margin-left: 0.5rem;
}
.search-type-input {
    visibility: visible;
    -webkit-tap-highlight-color: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    background-color: transparent;
    font-size: .9rem;
    outline: none;
    border: none;
    border-radius: 0;
    padding: 0;
    display: block;
    width: auto;
    margin: .25rem 0 .25rem .25rem;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 2%);
    height: 2.125rem;
    line-height: 2.125rem;
    border-radius: 4px;
    border: 0;
    background: #fff;
}
.search-title-more-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
}
.whitebear-page-controller {
    -webkit-text-size-adjust: 100%;
    font-size: .875rem;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    color: rgba(0, 0, 0, 0.8);
    line-height: 1.2;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    margin: 1.25rem 0 1.25rem;
}
@media only screen and (min-width: 600px) {
    .whitebear-icon-button--left {
        margin-right: .9375rem;
    }
  }
@media only screen and (max-width: 600px) {
    .whitebear-icon-button--left {
        margin-right: .3rem;
    }
}
.whitebear-icon-button--left {
    -webkit-text-size-adjust: 100%;
    font: inherit;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    outline: none;
    cursor: pointer;
    line-height: 1;
    letter-spacing: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4,0,.6,1);
    background-color: transparent;
    color: rgba(0,0,0,.4);
    min-width: 1.75rem;
    border: none;
    text-align: right;
}
@media only screen and (min-width: 600px) {
    .whitebear-icon-button--right {
        margin-left: .9375rem;
    }
  }
@media only screen and (max-width: 600px) {
    .whitebear-icon-button--right {
        margin-left: .3rem;
    }
}
.whitebear-icon-button--right {
    -webkit-text-size-adjust: 100%;
    font: inherit;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    outline: none;
    cursor: pointer;
    line-height: 1;
    letter-spacing: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4,0,.6,1);
    background-color: transparent;
    padding: 0;
    color: rgba(0,0,0,.4);
    min-width: 1.75rem;
    border: none;
    text-align: left;
}
@media only screen and (min-width: 600px) {
    .whitebear-button-solid--primary {
        margin-left: .9375rem;
        margin-right: .9375rem;
        min-width: 2.5rem;
        font-size: 1.25rem;
        height: 2.25rem;
    }
  }
@media only screen and (max-width: 600px) {
    .whitebear-button-solid--primary {
        margin-left: .2rem;
        margin-right: .2rem;
        min-width: 2rem;
        font-size: 1.25rem;
        height: 1.5rem;
    }
}
.whitebear-button-solid--primary {
    -webkit-text-size-adjust: 100%;
    font: inherit;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    outline: none;
    cursor: pointer;
    border: none;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    transition: opacity .2s ease;
    border-radius: 2px;
    user-select: none;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.09);
    background: #ee4d2d;
    padding: 0;
    text-align: center;
    color: #fff;
}
@media only screen and (min-width: 600px) {
    .whitebera-button-no-outline {
        margin-left: .9375rem;
        margin-right: .9375rem;
        min-width: 2.5rem;
        font-size: 1.25rem;
        height: 2.25rem;
    }
  }
@media only screen and (max-width: 600px) {
    .whitebera-button-no-outline {
        margin-left: .2rem;
        margin-right: .2rem;
        min-width: 2rem;
        font-size: 1.25rem;
        height: 1.5rem;
    }
}
.whitebera-button-no-outline {
    -webkit-text-size-adjust: 100%;
    font: inherit;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    outline: none;
    cursor: pointer;
    letter-spacing: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4,0,.6,1);
    background-color: transparent;
    border: none;
    color: rgba(0,0,0,.4);
    padding: 0;
    text-align: center;
}
.whitebear-arrow-icon {
    -webkit-text-size-adjust: 100%;
    font: inherit;
    text-transform: none;
    cursor: pointer;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    color: rgba(0,0,0,.4);
    text-align: right;
    x: 0;
    y: 0;
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
}