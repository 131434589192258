.shopping-cart-container {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    background: #f5f5f5;
    font-size: .875rem;
    margin-bottom: 1rem;
}
@media only screen and (min-width: 600px) {
    .shopping-cart-content {
        height: calc(100vh - 106px - 2rem);
        overflow: auto;
    }
}
@media only screen and (max-width: 600px) {
    .shopping-cart-content {
        height: calc(100vh - 124px);
        overflow: auto;
    }
}

.cart-page-product-header {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    align-items: center;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.05);
    overflow: hidden;
    border-radius: .2rem;
    height: 3.4375rem;
    background: #fff;
    text-transform: capitalize;
    margin-bottom: 12px;
    color: #888;
    display: flex;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}
.cart-page-product-header-tab {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    align-items: center;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.05);
    overflow: hidden;
    border-radius: .2rem;
    height: 3.4375rem;
    background: #fff;
    text-transform: capitalize;
    margin-bottom: 12px;
    color: #888;
    display: flex;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    margin-top: 2rem;
}

.shopping-cart-footer {
    height: 10rem;
    width: 100%;
    background-color: transparent;
}
.cart-payment-button {
    font: inherit;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,.26);
    padding: 8px 10px;
    background-color: #ee4d2d;
    border-color: #cd3011;
    color: #fff;
}
.cart-payment-container-top {
    width: 100%;
  }
.cart-payment-container {
    margin: auto;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border: 1px solid rgba(0,0,0,.09);
    border: 1px solid rgba(0,0,0,.09);
    padding: .5rem .75rem calc(env(safe-area-inset-bottom, 0) + 8px);
}
.cart-total-money {
    --bg: #f0f0f0;
    --shimmer: #e0e0e0;
    color: rgba(0,0,0,.87);
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    text-align: right;
    box-sizing: border-box;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    font-size: 18px;
    line-height: 16px;
}
.cart-total-money-text {
    -webkit-text-size-adjust: 100%;
    --safe-area-inset-bottom: env(safe-area-inset-bottom,0);
    --bg: #f0f0f0;
    --shimmer: #e0e0e0;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    text-align: right;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    font-size: 20px;
    line-height: 18px;
    font-weight: 500;
    color: #ee4d2d;
}
.payment-body {
    max-width: 1200px;
    margin: auto;
}
