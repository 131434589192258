.header-with-search-wrapper {
    box-sizing: border-box;
    z-index: 400;
    background: transparent;
    min-width: inherit;
  }
  @media only screen and (max-width: 600px) {
    .header-with-search {
    }
  }
  @media only screen and (min-width: 600px) {
    .header-with-search {
      /* height: 5.3125rem; */
    }
  }
  .header-with-search {
    width: inherit;
    max-width: 1200px;
    box-sizing: border-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: .2rem 0 .625rem;
    margin-right: auto;
    margin-left: auto;
  }
  @media only screen and (max-width: 600px) {
    .header-with-search-logo-section {
    }
  }
  @media only screen and (min-width: 600px) {
    .header-with-search-logo-section {
      top: -.1875rem;
    }
  }
  .header-with-search-logo-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-with-search-logo-svg {
    display: flex;
    position: relative;
    z-index: 500;
    fill-rule: evenodd;
    transform-origin: 0px 0px;
  }
  .header-with-search__search-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 840px;
    position: relative;
  }
  .header-with-search-logo-sv {
    width: 162px;
    height: 50px; 
  }
  .white-bear-search-bar {
    width: 100%;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 9%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -moz-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 2.5rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: .1875rem;
    border-radius: 2px;
    background: #fff;
  }
  .white-bear-searchbar__main {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .white-bear-searchbar-input {
    background-color: #fff;
    border-color: #fff;
    box-sizing: border-box;
    padding-left: .625rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .searchbar-input__input {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    font-size: 16px;
  }
  .btn-s {
    height: 34px;
    padding: 0 15px;
    min-width: 60px;
    max-width: 190px;
    display: inline-flex;
    color: #fff;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 14px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 9%);
    border-radius: 2px;
    border: 0;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: capitalize;
    outline: 0;
    cursor: pointer;
    color: #fff;
    background: #ee4d2d;
}
.svg-icon {
  margin: auto;
  overflow: hidden;
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  position: relative;
}
@media only screen and (max-width: 600px) {
  .shortBar {
    display: flex;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) {
  .shortBar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media only screen and (max-width: 600px) {
  .shortBarContent {
    margin-bottom: .2rem;
    font-size: .75rem;
    font-weight: 300;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow: hidden;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-left: .5rem;
    margin-right: .5rem;
  }
}
@media only screen and (min-width: 600px) {
  .shortBarContent {
    margin-top: 5px;
    font-size: .75rem;
    font-weight: 300;
    line-height: .875rem;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow: hidden;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media only screen and (max-width: 600px) {
  .shortBarItem {
    line-height: 1rem;
    text-decoration: none;
    color: rgba(0,0,0,.8);
    white-space: nowrap;
    color: rgba(0,0,0,.54);
    color: hsla(0,0%,100%,.9);
    font-size: .75rem;
    font-weight: 300;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow: hidden;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    list-style: none;
    flex: 0 0 33.333333%;
    margin-bottom: .05rem;
  }
}
@media only screen and (min-width: 600px) {
  .shortBarItem {
  margin-left: 0;
  text-decoration: none;
  color: rgba(0,0,0,.8);
  white-space: nowrap;
  display: block;
  color: rgba(0,0,0,.54);
  margin-right: 13px;
  color: hsla(0,0%,100%,.9);
  height: .875rem;
  font-size: .75rem;
  font-weight: 300;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  }
}
@media only screen and (max-width: 600px) {
  .header-with-search__cart-wrapper {
    margin: 0 10px;
  }
}
@media only screen and (min-width: 600px) {
  .header-with-search__cart-wrapper {
    padding-bottom: 5px;
    margin: 0 10px;
  }
}
.header-with-search__cart-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  box-sizing: border-box;
}
.stardust-popover {
  position: relative;
}
.cart-drawer {
  cursor: pointer;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 5px;
}
.flex {
  display: flex;
}
.v-center {
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .icon-shop-cart {
  }
}
@media only screen and (min-width: 600px) {
  .icon-shop-cart {
    margin-bottom: 10px;
  }
}
.icon-shop-cart {
  fill: currentColor;
  cursor: pointer;
  color: #fff;
  stroke: #fff;
  color: #fff;
  display: inline-block;
  width: 2em;
  height: 2em;
  fill: currentColor;
  position: relative;
}
.circle-shop-cart {
  fill: currentColor;
  width: 26px;
  height: 26px;
  cursor: pointer;
  color: #fff;
  stroke: #fff;
  font-size: 1.0625rem;
  color: #fff;
}
.cart-number-badge {
  position: absolute;
  border-radius: 3rem;
  line-height: 1.2em;
  padding: 0 .3125rem;
  text-align: center;
  border: .125rem solid #ee4d2d;
  color: #ee4d2d;
  background-color: #fff;
  top: -.55rem;
  left: 1rem;
}