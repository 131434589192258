.cart-item-container {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}
.mobile-image {
    width: 6rem;
}
.mobile-content {
    display: block;
    margin-left: 1rem;
    width: 100%;
    height: 100%;
}
.cart-item__cell-checkbox {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    text-transform: capitalize;
    color: #888;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 10px;
}
@media only screen and (min-width: 600px) {
    .stardust-checkbox {
        font-size: 12px;
    }
  }
@media only screen and (max-width: 600px) {
    .stardust-checkbox {
        font-size: 8px;
    }
}
.stardust-checkbox {
    -webkit-text-size-adjust: 100%;
    line-height: 1.2;
    text-transform: capitalize;
    -webkit-box-direction: reverse;
    font-family: -apple-system,HelveticaNeue-Light,Helvetica Neue Light,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 300;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: default;
    position: relative;
    color: rgba(0,0,0,.54);
    max-width: 400px;
}
.stardust-checkbox__input {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: reverse;
    color: inherit;
    font: inherit;
    margin: 0;
    line-height: normal;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    box-sizing: border-box;
    padding: 0;
}
@media only screen and (min-width: 600px) {
    .stardust-remove-button {
        font-size: 12px;
        width: 25px;
        height: 25px;
        margin-right: 8px;
    }
  }
@media only screen and (max-width: 600px) {
    .stardust-remove-button {
        font-size: 8px;
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }
}
.stardust-remove-button {
    -webkit-text-size-adjust: 100%;
    line-height: 1.2;
    text-transform: capitalize;
    -webkit-box-direction: reverse;
    font-family: -apple-system,HelveticaNeue-Light,Helvetica Neue Light,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 300;
    cursor: default;
    color: red;
    user-select: none;
    position: relative;
    flex-shrink: 0;
    text-align: center;
    box-shadow: inset 0 2px 0 0 rgba(0,0,0,.02);
}

@media only screen and (min-width: 600px) {
    .cart-page-product-header {
        padding-left: .5rem;
    }
  }
  @media only screen and (min-width: 600px) {
    .cart-page-product-header__product {
        margin-left: 1rem;
    }
  }
.cart-page-product-header__product {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    text-transform: capitalize;
    color: rgba(0,0,0,.8);
    width: 46.27949%;
}
.cart-page-product-header__unit-price {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    text-transform: capitalize;
    color: #888;
    width: 15.88022%;
    text-align: center;
}
.cart-page-product-header__quantity {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    text-transform: capitalize;
    color: #888;
    width: 15.4265%;
    text-align: center;
}
.cart-page-product-header__total-price {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    text-transform: capitalize;
    color: #888;
    width: 10.43557%;
    text-align: center;
}
.cart-page-product-header__action {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    text-transform: capitalize;
    color: #888;
    width: 12.70417%;
    text-align: center;
}

.cart-page-shop-section {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    color: rgba(0,0,0,.8);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.05);
    border-radius: .2rem;
    background: #fff;
    margin-bottom: .9375rem;
    overflow: visible;
}
.cart-page-shop-section-mobile {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    color: rgba(0,0,0,.8);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.05);
    border-radius: .2rem;
    background: #fff;
    /* margin-bottom: .9375rem; */
    overflow: visible;
}
.cart-page-shop-section__items {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    color: rgba(0,0,0,.8);
    position: relative;
    padding-bottom: 1px;
}
.cart-item {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    text-decoration: none;
    color: rgba(0,0,0,.8);
    display: block;
    margin-top: .9375rem;
    padding-top: .9375rem;
    padding-bottom: 1.25rem;
    border: none;
}
.cart-item-mobile {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    text-decoration: none;
    color: rgba(0,0,0,.8);
    display: block;
    margin-top: .3375rem;
    padding-top: .3375rem;
    border: none;
}
.cart-item__content-mobile {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    color: rgba(0,0,0,.8);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: .5rem;
}
.cart-item__content {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    color: rgba(0,0,0,.8);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.cart-item__content-ship {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    -webkit-box-direction: normal;
    color: rgba(0,0,0,.8);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-between;
}
.cart-item__cell-overview {
    line-height: 1.2;
    color: rgba(0,0,0,.8);
    width: 46.27949%;
}
.cart-item__cell-overview-shipping {
    line-height: 1.2;
    color: rgba(0,0,0,.8);
    font-size: .75rem;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: flex;
}
.cart-item__cell-overview-mobile {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    color: rgba(0,0,0,.8);
    box-sizing: border-box;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}
.cart-item__cell-overview--line {
    line-height: 1.2;
    color: rgba(0,0,0,.8);
    display: flex;
    align-items: center;
}
.cart-item__cell-overview--line-mobile {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    color: rgba(0,0,0,.8);
    -webkit-box-direction: normal;
    display: flex;
    width: fit-content;
}
.cart-item-overview__product-name-wrapper {
    margin-left: 1rem;
}
.cart-item-overview__product-name-wrapper-mobile {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    color: rgba(0,0,0,.8);
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    line-height: 1rem;
    overflow: hidden;
}
.cart-item-overview__name {
    color: rgba(0,0,0,.8);
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.cart-item-overview__name-mobile {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    -webkit-box-direction: normal;
    background-color: transparent;
    text-decoration: none;
    color: rgba(0,0,0,.8);
    margin-bottom: .8125rem;
    line-height: 1rem;
    max-height: 2rem;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.cart-item__cell-unit-price {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    color: rgba(0,0,0,.8);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 15.88022%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}
.cart-item__cell-unit-price-mobile {
    line-height: 1;
    color: rgba(0,0,0,.8);
    margin-bottom: .5rem;
    font-size: 11px;
    font-style: italic;
}
.cart-item__cell-quantity {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    color: rgba(0,0,0,.8);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 15.4265%;
}
.cart-item__cell-quantity-mobile {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    color: rgba(0,0,0,.8);
    margin-bottom: .5rem;
}
.input-quantity {
    -webkit-text-size-adjust: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, 文泉驛正黑, "WenQuanYi Zen Hei", "Hiragino Sans GB", "儷黑 Pro", "LiHei Pro", "Heiti TC", 微軟正黑體, "Microsoft JhengHei UI", "Microsoft JhengHei", sans-serif;
    line-height: 1.2;
    color: rgba(0,0,0,.8);
    -webkit-box-direction: normal;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
@media only screen and (min-width: 600px) {
    .whitebear-svg-icon {
        font-size: 10px;
        width: 10px;
        height: 10px;
    }
  }
@media only screen and (max-width: 600px) {
    .whitebear-svg-icon {
        font-size: 6px;
        width: 6px;
        height: 6px;
    }
}
.whitebear-svg-icon {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    font: inherit;
    text-transform: none;
    cursor: pointer;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    color: rgba(0,0,0,.8);
    x: 0;
    y: 0;
    display: inline-block;
    fill: currentColor;
    position: relative;
    overflow: hidden;
}
@media only screen and (min-width: 600px) {
    .whitebear-svg-icon-plus-sign {
        font-size: 10px;
        width: 10px;
        height: 10px;
    }
  }
@media only screen and (max-width: 600px) {
    .whitebear-svg-icon-plus-sign {
        font-size: 6px;
        width: 6px;
        height: 6px;
    }
}
.whitebear-svg-icon-plus-sign {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    font: inherit;
    text-transform: none;
    cursor: pointer;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    color: rgba(0,0,0,.8);
    x: 0;
    y: 0;
    display: inline-block;
    fill: currentColor;
    position: relative;
    overflow: hidden;
}
@media only screen and (min-width: 600px) {
    .plus-icon-shop {
        width: 32px;
        height: 32px;
    }
  }
@media only screen and (max-width: 600px) {
    .plus-icon-shop {
        width: 24px;
        height: 24px;
    }
}
.plus-icon-shop {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    outline: none;
    cursor: pointer;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4,0,.6,1);
    border: 1px solid rgba(0,0,0,.09);
    border-radius: 2px;
    background: transparent;
    color: rgba(0,0,0,.8);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
@media only screen and (min-width: 600px) {
    .quality-input {
        width: 50px;
        height: 32px;
    }
  }
@media only screen and (max-width: 600px) {
    .quality-input {
        width: 30px;
        height: 24px;
    }
}
.quality-input {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    font: inherit;
    margin: 0;
    outline: none;
    line-height: 1;
    letter-spacing: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4,0,.6,1);
    border: 1px solid rgba(0,0,0,.09);
    background: transparent;
    color: rgba(0,0,0,.8);
    border-left: none;
    border-right: none;
    font-weight: 400;
    box-sizing: border-box;
    text-align: center;
    cursor: text;
    border-radius: 0;
    -webkit-appearance: none;
}
.cart-item__cell-total-price {
    -webkit-text-size-adjust: 100%;
    line-height: 1.2;
    -webkit-box-direction: normal;
    width: 10.43557%;
    text-align: center;
    color: #ee4d2d;
}
.cart-item__cell-total-price-mobile {
    -webkit-text-size-adjust: 100%;
    line-height: 1;
    -webkit-box-direction: normal;
    color: #ee4d2d;
    font-size: 12px;
}
.cart-item__cell-actions-cart-item__cell-actions--multiple {
    -webkit-text-size-adjust: 100%;
    line-height: 1.2;
    color: rgba(0,0,0,.8);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 12.70417%;
    text-transform: capitalize;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}
.cart-item__cell-actions-cart-item__cell-actions--multiple-mobile {
    -webkit-text-size-adjust: 100%;
    line-height: 1.2;
    color: rgba(0,0,0,.8);
    /* margin-left: 0.5rem; */
    margin-right: 0.5rem;
}
.cart-item__action {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: inherit;
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
    background: none;
    border: none;
}
.result-delete-button-mobile {
    border: 0;
    cursor: pointer;
    background-color: #ee4d2d;
    color: #fff;
    border-radius: 2px;
    min-width: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .5rem;
}
@media only screen and (max-width: 600px) {
    .cart-item-save {
        margin-right: .2rem;
    }
}
.cart-item-save {
    background: #1E8FA8;
    padding: 0.5rem;
    border-radius: 0.2rem;
}
.cart-item-save-text {
    color: #fff;
}
.ship-item-text {
    padding-top: 0.2rem;
}