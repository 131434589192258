.ul-test-home-page {
    width: max-content;
} 
.li-test-home-page {
    position: relative;
    float: left;
    margin-left: 10px;
    text-decoration: none;
    color: rgba(0,0,0,.8);
    box-shadow: 0 0.0625rem 0.125rem 0 rgb(0 0 0 / 10%);
    border-bottom: 1px red;
    margin-bottom: 1.3rem;
    background: white;
}
.li-test-home-page-content {
    position: relative;
    float: left;
    text-decoration: none;
}
.image-test {
    padding: 0;
}
.price-item-home-page {
    font-size: 1rem;
    color: #ee4d2d;
}
.div-test-home-page {
    display: flex;
}
.list-title-scroll {
    display: flex;
    position: relative;
    max-width: 100%;
    position: relative;
    border-bottom-width: .5px;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(
    -180deg
    ,#f53d2d,#f63);
}
.list-title-name {
    color: #f0f0f0;
    font-size: 1rem;
    font-weight: 600;
    padding: .5rem;
    padding-left: 1rem;
    text-transform: uppercase;
}
.list-title-more {
    vertical-align: center;
    margin-right: 1rem;
    color: #f0f0f0;
    font-size: .875rem;
    font-weight: 300;
}
.list-title-more-bottom {
    -webkit-text-size-adjust: 100%;
    line-height: 1.2;
    -webkit-box-direction: normal;
    text-align: center;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex-direction: column;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 2px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-transform: capitalize;
    outline: 0;
    cursor: pointer;
    display: inline-flex;
    padding: 0 5rem;
    background: #fff;
    border: 1px solid rgba(0,0,0,.09);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.03);
    color: #555;
    height: 2.5rem;
    margin-bottom: .5rem;
}
.list-title-more-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}
.item-title-home-page {
    overflow: hidden;
    display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 14px;
    font-size: .75rem;
    text-decoration: none;
    color: rgba(0,0,0,.8);
    flex-direction: column;
    height: 1.75rem;
    user-select: none;
    padding-left: 0.3175rem;
    margin-top: 0.3175rem;
}
@media only screen and (min-width: 600px) {
    .scroll-list-container {
        margin-top: 20px;
        padding-bottom: 10px;
    }
  }
@media only screen and (max-width: 600px) {
    .scroll-list-container {
        padding-top: .5rem;
    }
  }
.scroll-list-container {
    background: white;
}
.vnd {
    font-size: .75rem;
    color: #FF4165;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.number-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1rem;
    color: #FF4165;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.bottom-item {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -moz-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    padding-left: 0.3175rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    line-height: 1.5rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    background: #f5f5f5;
}
.count {
    flex-shrink: 0;
    color: rgba(0,0,0,.54);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: .3125rem;
    font-size: .75rem;
    padding-right: 0.3175rem;
}

.plus-unit-item {
    display: flex;
    background: #FF4165;
    border-radius: .3rem;
}
.plus-unit-item-walk-through {
    display: flex;
    background: #FF4165;
    border-radius: .3rem;
    width: 2.1rem;
}
.plus-unit-image {
    fill: currentColor;
    cursor: pointer;
    color: white;
    stroke: white;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    fill: currentColor;
    position: relative;
    margin-left: .3rem;
    margin-right: .3rem;
    margin-top: .3rem;
    margin-bottom: .3rem;
  }
