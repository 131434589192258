#footer {
    height: 2rem;
    width: inherit;
    text-align: center;
    font-size: .75rem;
    color: rgba(0,0,0,.65);
    background: #f5f5f5;
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgb(245, 245, 245);
  }
.footer-title {
  font-size: 0.75rem;
  color: rgba(0,0,0,.65);
}

.footer-text {
  font-size: 0.5rem;
  color: rgba(0,0,0,.65);
}
