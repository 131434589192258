@media only screen and (min-width: 600px) {
    .full-body {
      /* padding-top: 14vh; */
      margin-left: 1rem;
      padding-right: 1rem;
    }
  }
@media only screen and (min-width: 600px) {
    .home-page {
        height: calc(100vh - 106px - 2rem);
    }
}
@media only screen and (max-width: 600px) {
    .home-page {
        height: calc(100vh - 124px - 2rem);
    }
}
.home-page {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #f5f5f5;
    margin-right: auto;
    margin-left: auto;
    overflow: auto;
    /* max-width: 1200px; */
}
.home-page-bottom {
  width: 100vw;
  margin-top: 5rem;
}
.gif-image {
    height: 50vh;
    align-self: center;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.home-place-holder-container {
  width: 100vw;
  height: 80vh;
  background: #f5f5f5;
}
.home-place-holder-content {
  margin-top: 200px;
}
