#app {
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    font-size: .875rem;
    color: rgba(0,0,0,.87);
    visibility: visible;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    display: block;
    overflow: auto;
}
.full-body-update-admin {
    background-color: #f5f5f5;
    display: block;
    margin: auto;
    height: calc(100vh - 1rem);
    overflow: auto;
    max-width: 100%;
    overflow-x: hidden;
  }
.app-container {
    font-size: .875rem;
    color: rgba(0,0,0,.87);
    visibility: visible;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    margin: 2.75rem auto 0;
    /* max-width: 37.5rem; */
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
}
.navigation-bar {
    font-size: .875rem;
    color: rgba(0,0,0,.87);
    visibility: visible;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2.75rem;
    background-color: #fff;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.06);
    box-sizing: content-box;
    z-index: 100;
    /* max-width: 37.5rem; */
    margin: 0 auto;
}
.back-button {
    font-size: .875rem;
    color: rgba(0,0,0,.87);
    visibility: visible;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    -webkit-tap-highlight-color: transparent;
    fill: #ee4d2d;
    width: 1.5625rem;
    height: 1.5625rem;
    user-select: none;
    cursor: pointer;
    margin: 0 .9375rem;
    flex-shrink: 0;
    overflow: hidden;
}
.login-title {
    visibility: visible;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-flex: 1;
    flex: 1;
    font-size: 1.25rem;
    color: rgba(0,0,0,.87);
    max-width: calc(100vw - 58px);
    font-weight: 400;
    text-align: center;
    padding-right: 2.5rem;
}
.login-text {
    visibility: visible;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-size: 1.25rem;
    color: rgba(0,0,0,.87);
    font-weight: 400;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.form-container {
    font-size: .875rem;
    color: rgba(0,0,0,.87);
    visibility: visible;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    padding: 1.375rem;
    max-width: 1200px;
    margin: auto;
}
.logo-container {
    font-size: .875rem;
    color: rgba(0,0,0,.87);
    visibility: visible;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 2.5rem 0 1.875rem;
}
.logo {
    font-size: .875rem;
    visibility: visible;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    -webkit-tap-highlight-color: transparent;
    stroke: currentColor;
    fill: currentColor;
    overflow: hidden;
    color: #ee4d2d;
}
.user-name-container {
    font-size: .875rem;
    color: rgba(0,0,0,.87);
    visibility: visible;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 3rem;
    border: none;
    box-sizing: border-box;
    padding: 0 .125rem;
    border-bottom: .5px solid rgba(0,0,0,.26);
    justify-content: space-between;
}
.description-container {
    font-size: .875rem;
    color: rgba(0,0,0,.87);
    visibility: visible;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    width: 100%;
    border: none;
    box-sizing: border-box;
    padding: 0 .125rem;
}
.description-text-box {
    visibility: visible;
    -webkit-tap-highlight-color: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    background-color: transparent;
    font-size: 1rem;
    outline: none;
    border: none;
    border-radius: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: unset;
    min-height: 4rem;
    margin: .25rem 0 .25rem .25rem;
    border-bottom: .5px solid rgba(0,0,0,.26);
}
.user-name-icon {
    font-size: .875rem;
    color: rgba(0,0,0,.87);
    visibility: visible;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    -webkit-tap-highlight-color: transparent;
    stroke: currentColor;
    fill: currentColor;
    flex-shrink: 0;
    width: 1.125rem;
    height: 1.125rem;
    padding: .1875rem;
    margin-right: .625rem;
    overflow: hidden;
}
.user-name-input {
    visibility: visible;
    -webkit-tap-highlight-color: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    background-color: transparent;
    font-size: 1rem;
    outline: none;
    border: none;
    border-radius: 0;
    padding: 0;
    display: block;
    width: 100%;
    margin: .25rem 0 .25rem .25rem;
}
.type-input {
    visibility: visible;
    -webkit-tap-highlight-color: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    background-color: transparent;
    font-size: 1rem;
    outline: none;
    border: none;
    border-radius: 0;
    padding: 0;
    display: block;
    width: auto;
    margin: .25rem 0 .25rem .25rem;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 2%);
    height: 2.125rem;
    line-height: 2.125rem;
    border-radius: 4px;
    border: 0;
    background: #EDE2EE;
    
}
.password-icon {
    font-size: .875rem;
    color: rgba(0,0,0,.87);
    visibility: visible;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    -webkit-tap-highlight-color: transparent;
    stroke: currentColor;
    fill: currentColor;
    flex-shrink: 0;
    width: 1.125rem;
    height: 1.125rem;
    padding: .1875rem;
    margin-right: .625rem;
    overflow: hidden;
}
.login-button-disable {
    visibility: visible;
    font: inherit;
    margin: 0;
    overflow: visible;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    padding: 0;
    border: 0;
    height: 2.625rem;
    width: 100%;
    text-transform: capitalize;
    border-radius: 2px;
    color: rgba(0,0,0,.26);
    background-color: rgba(0,0,0,.09);
    margin-top: .875rem;
    cursor: default;
}
.login-button-enable {
    visibility: visible;
    font: inherit;
    margin: 0;
    overflow: visible;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    padding: 0;
    border: 0;
    cursor: pointer;
    height: 2.625rem;
    width: 100%;
    background-color: #ee4d2d;
    color: #fff;
    text-transform: capitalize;
    border-radius: 2px;
    margin-top: .875rem;
}
.admin-button {
    visibility: visible;
    font: inherit;
    margin: auto;
    overflow: visible;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    padding: auto;
    border: 0;
    cursor: pointer;
    height: 2.625rem;
    background-color: #ee4d2d;
    color: #fff;
    text-transform: capitalize;
    border-radius: 2px;
    margin-top: .875rem;
    padding-right: 1rem;
    padding-left: 1rem;
    min-width: 20rem;
}
.admin-button-container {
    display: flex;
    flex-direction: column;
}
.or-container {
    font-size: .875rem;
    color: rgba(0,0,0,.87);
    visibility: visible;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
}
.or-line {
    font-size: .875rem;
    color: rgba(0,0,0,.87);
    visibility: visible;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    width: 25%;
    border: .5px solid rgba(0,0,0,.12);
    max-width: 200px;
}
.or-text {
    font-size: .875rem;
    visibility: visible;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    color: rgba(0,0,0,.54);
    padding: 0 .9375rem;
    text-transform: uppercase;
}
.fb-container {
    visibility: visible;
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    width: 100%;
    max-width: 500px;
    margin: auto;
    height: 2.5rem;
    padding: .0625rem;
    border-radius: 2px;
    outline: none;
    background: none;
    border: none;
    background-color: #1877f2;
    color: #fff;
    margin-top: .5rem;
}
.fb-icon-content {
    visibility: visible;
    font: inherit;
    text-transform: none;
    cursor: pointer;
    color: #fff;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 2.375rem;
    height: 2.375rem;
    border-radius: 1px;
    flex-shrink: 0;
}
.fb-icon {
    visibility: visible;
    font: inherit;
    text-transform: none;
    cursor: pointer;
    color: #fff;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    width: 1.375rem;
    height: 1.375rem;
    background-image: url(https://i.ibb.co/bXqwRhG/dee4c9b853f18a1ce0571f6600ad2c5d.png);
    background-size: 372% 232%;
    background-position: 76.47058823529412% 15.151515151515152%;
}
.fb-text {
    visibility: visible;
    font: inherit;
    text-transform: none;
    cursor: pointer;
    color: #fff;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    text-align: center;
    font-weight: 500;
    margin-right: 1.375rem;
}
.gg-container {
    visibility: visible;
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 2.5rem;
    padding: .0625rem;
    border-radius: 2px;
    outline: none;
    background: none;
    border: none;
    background-color: #4285f4;
    color: #fff;
    margin-top: .5rem;
    max-width: 500px;
    margin: auto;
}
.gg-icon-content {
    visibility: visible;
    font: inherit;
    text-transform: none;
    cursor: pointer;
    color: #fff;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 2.375rem;
    height: 2.375rem;
    border-radius: 1px;
    flex-shrink: 0;
    background-color: #fff;
}
.gg-icon {
    visibility: visible;
    font: inherit;
    text-transform: none;
    cursor: pointer;
    color: #fff;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    width: 1.125rem;
    height: 1.125rem;
    margin: 0 .125rem;
    background-image: url(https://i.ibb.co/bXqwRhG/dee4c9b853f18a1ce0571f6600ad2c5d.png);
    background-size: 516.6666666666666% 322.22222222222223%;
    background-position: 100% 100%;
}
.gg-text {
    visibility: visible;
    font: inherit;
    text-transform: none;
    cursor: pointer;
    color: #fff;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    text-align: center;
    font-weight: 500;
    margin-right: 1.375rem;
}
.admin-footer {
    height: 6rem;
    width: 100vw;
}
