.cart-payment-screen-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-top: 1px solid rgba(0,0,0,.09);
    padding: .5rem .75rem calc(env(safe-area-inset-bottom, 0) + 8px);
}
.cart-total-screen-money {
    --bg: #f0f0f0;
    --shimmer: #e0e0e0;
    color: rgba(0,0,0,.87);
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    text-align: right;
    box-sizing: border-box;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    font-size: 18px;
    line-height: 16px;
}
.cart-total-money-screen-text {
    -webkit-text-size-adjust: 100%;
    --safe-area-inset-bottom: env(safe-area-inset-bottom,0);
    --bg: #f0f0f0;
    --shimmer: #e0e0e0;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    text-align: right;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    font-size: 20px;
    line-height: 18px;
    font-weight: 500;
    color: #ee4d2d;
}
.payment-info-container {
    background: #fff;
    border-radius: .3rem;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.03);
}
.payment-info-text {
    margin-top: 1rem;
}
.payment-info-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;
}
.payment-info-input {
    background: #f5f5f5;
    height: 2rem;
    border-width: .1rem;
    width: 70%;
    text-align: right;
    padding-right: .5rem;
}
.payment-info-title {
    font-weight: 400;
    -webkit-box-direction: normal;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    color: rgba(0,0,0,.54);
    font-size: 14px;
    line-height: 14px;
    background: rgba(245, 245, 245);
    height: 3rem;
    display: flex;
    align-items: center;
    padding: .5rem;
}
.payment-info-button-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.payment-info-button {
    font: inherit;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    font-family: -apple-system,Helvetica Neue,Helvetica,Roboto,Droid Sans,Arial,sans-serif;
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,.26);
    background-color: #ee4d2d;
    border-color: #cd3011;
    color: #fff;
    width: 60%;
    height: 2.7rem;
    margin: auto;
}
.payment-info-note {
    margin: auto;
}