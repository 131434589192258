.header {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: helvetica neue,Helvetica,Arial,sans-serif;
    font-size: 1.125rem;
    color: #444;
    box-sizing: border-box;
    background: linear-gradient(135deg,#0452a5 0%,#0053a7 50%,#2d60d8 79%,#4779f3 100%);
    display: inline-block;
    width: 100%;
    height: 4rem;
}
.container {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: helvetica neue,Helvetica,Arial,sans-serif;
    font-size: 1.125rem;
    color: #444;
    box-sizing: border-box;
    position: relative;
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.logo {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: helvetica neue,Helvetica,Arial,sans-serif;
    box-sizing: border-box;
    display: block;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 700;
    padding: 20px 0;
}
.section_container {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: helvetica neue,Helvetica,Arial,sans-serif;
    font-size: 1.125rem;
    color: #444;
    line-height: 1.7;
    word-wrap: break-word;
    box-sizing: border-box;
    animation: fade 1s;
    display: block;
    opacity: 1;
}
.agreement_md {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: helvetica neue,Helvetica,Arial,sans-serif;
    font-size: 1.125rem;
    color: #444;
    line-height: 1.7;
    word-wrap: break-word;
    box-sizing: border-box;
    margin: auto;
    max-width: 1200px;
}

h1 {
    font-weight: bold;
    color: #000;
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: 1.4;
}

p {
    color: #444;
    line-height: 1.4;
    word-wrap: break-word;
    box-sizing: border-box;
    font-size: 0.8rem;
    margin-bottom: 10px;
}

h2 {
    font-weight: 700;
    padding-bottom: 0;
    color: #000;
    font-size: 1.375rem;
    margin-top: 20px;
    margin-bottom: 10px;
}

ul {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: helvetica neue,Helvetica,Arial,sans-serif;
    color: #444;
    word-wrap: break-word;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1rem;
    list-style-type: disc;
    line-height: 2;
    margin-left: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
}

h3 {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: helvetica neue,Helvetica,Arial,sans-serif;
    word-wrap: break-word;
    box-sizing: border-box;
    line-height: inherit;
    margin: 0;
    font-weight: 700;
    padding-bottom: 0;
    color: #000;
    font-size: 1.25rem;
    margin-top: 20px;
    margin-bottom: 10px;
}

.page {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: helvetica neue,Helvetica,Arial,sans-serif;
    font-size: 1.125rem;
    color: #444;
    box-sizing: border-box;
    padding: 0;
}